import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, timer } from "rxjs";
import { environment } from "../../../environments/environment";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { getConfService } from "./getConf.service";

@Injectable({
  providedIn: 'root'
})
export class SessionLoginService {
  LOGIN_URL = '/login';
  public isLoggedIn$: BehaviorSubject<boolean>;

  // Durée de session en millisecondes (20 minutes)
  private sessionDuration = 20 * 60 * 1000;
  private sessionTimerSubscription: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    public confService: getConfService
  ) {
    const isLoggedIn = localStorage.getItem('loggedIn') === 'true';
    this.isLoggedIn$ = new BehaviorSubject(isLoggedIn);

    // Vérifie si la session a expiré à l'initialisation
    this.checkSessionExpiration();
  }

  login(pEmail: string, pPassword: string): Observable<boolean> {
    const loginData = {
      email: pEmail,
      password: pPassword,
    };

    return new Observable<boolean>((observer) => {
      this.http.post(environment.baseLoginUrl + this.LOGIN_URL, loginData)
        .pipe(
          map((response: any) => {
            // Stocker le token et la date d'expiration dans le localStorage
            localStorage.setItem('token', response.data.token);
            const expirationTime = Date.now() + this.sessionDuration;
            localStorage.setItem('sessionExpires', expirationTime.toString());
          })
        )
        .subscribe(
          result => {
            observer.next(true);
            observer.complete();

            this.confService.getData();

            localStorage.setItem('loggedIn', 'true');
            this.isLoggedIn$.next(true);

            // Démarre le timer de session pour 20 minutes
            this.startSessionTimer();
          },
          error => {
            observer.error(false);
            observer.complete();
          }
        );
    });
  }

  logout() {
    // Nettoyage du localStorage
    localStorage.setItem('loggedIn', 'false');
    localStorage.removeItem('token');
    localStorage.removeItem('sessionExpires');
    this.isLoggedIn$.next(false);

    // Annule le timer de session s'il est actif
    if (this.sessionTimerSubscription) {
      this.sessionTimerSubscription.unsubscribe();
    }

    this.router.navigate(['/categories']);
  }

  /**
   * Démarre un timer qui forcera la déconnexion après la durée de session définie.
   */
  private startSessionTimer(): void {
    // Annuler tout timer précédent
    if (this.sessionTimerSubscription) {
      this.sessionTimerSubscription.unsubscribe();
    }

    // Créer un timer qui émettra après 20 minutes
    this.sessionTimerSubscription = timer(this.sessionDuration).subscribe(() => {
      this.logout();
    });
  }

  /**
   * Vérifie si la session a expiré selon la date stockée dans localStorage.
   * Si c'est le cas, l'utilisateur est déconnecté.
   */
  private checkSessionExpiration(): void {
    const expiration = localStorage.getItem('sessionExpires');
    if (expiration) {
      const expirationTime = parseInt(expiration, 10);
      if (Date.now() > expirationTime) {
        this.logout();
      } else {
        // Si la session n'a pas expiré, démarre le timer avec le temps restant
        const remainingTime = expirationTime - Date.now();
        this.sessionTimerSubscription = timer(remainingTime).subscribe(() => {
          this.logout();
        });
      }
    }
  }
}
