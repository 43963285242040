import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalService {

  private signalSource = new Subject<string>();
  signal$ = this.signalSource.asObservable();

  constructor() { }

  sendSignal(message: string): void {
    this.signalSource.next(message);
  }
}
