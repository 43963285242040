import { Component, Inject, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from "../../../../../environments/environment";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {SignalService} from "../../../services/signal.service";
import {Observable} from "rxjs";
import {TranslationService} from "../../../services/translation.service";
import {getConfService} from "../../../../core/services/getConf.service";  // Import MatDialogRef

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss']
})
export class ProductDialogComponent implements OnInit {

  productForm!: FormGroup;
  isLoading = false;
  allergenos: any[] = [];

  // Injecter MatDialogRef pour fermer la boîte de dialogue
  constructor(
    private getConfService: getConfService,
    private translationService: TranslationService,
    private _snackBar: MatSnackBar,
    private signalService: SignalService,
    private fb: FormBuilder, private http: HttpClient,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ProductDialogComponent>) {


  }

  ngOnInit(): void {
    let categoryId = this.data.categoryId;
    let product = this.data?.product;

    // Initialisation du formulaire avec un FormArray vide
    this.productForm = this.fb.group({
      id: [null],
      categoryId: [categoryId, [Validators.required]],
      title: ['', [Validators.required]],
      price: ['', [Validators.required]],
      description: ['', [Validators.required]],
      descriptionia: [''],
      image: [null, product ? [] : [Validators.required]],
      allergens: this.fb.array([])  // Initialisation d'un FormArray vide
    });

    // Récupérer les allergènes du service
    this.getConfService.getData().subscribe(
      (alergens) => {
        if (alergens?.alertgenos?.length) {
          this.allergenos = alergens?.alertgenos;

          // Patch les valeurs du produit dans le formulaire si un produit existe
          if (product) {
            this.productForm.patchValue({
              id: product.id,
              title: product.name,
              price: product.shortDescription,
              description: product.description,
              categoryId: product.defaultCategorieId,
            });

            // Ajouter les contrôles des allergènes
            this.addAllergenControls(product);
          }
        }
      },
      (error) => {
        console.error('Erreur lors du chargement des données:', error);
      }
    );
  }

  addAllergenControls(product: any) {
    const allergensFormArray = this.productForm.get('allergens') as FormArray;

    // Pour chaque allergène dans `this.allergenos`, on ajoute un contrôle au FormArray
    this.allergenos.forEach((allergen) => {
      const isSelected = product?.alergens?.some((productAllergen: any) => productAllergen.id === allergen.id);

      // Si l'allergène existe dans `product.alergens`, on le coche
      allergensFormArray.push(this.fb.control(isSelected));
    });
  }



// Getter pour accéder plus facilement au FormArray
  get allergens(): FormArray {
    return this.productForm.get('allergens') as FormArray;
  }




  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.productForm.patchValue({
        image: file
      });
    }
  }


  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  // Envoi des données vers le backend
  submitProduct(): void {
    if (this.productForm.valid) {
      this.isLoading = true;  // Activer le chargement

      const formData = new FormData();
      if (this.productForm.get('id')?.value) {
        formData.append('id', this.productForm.get('id')?.value);
      }

      formData.append('categoryId', this.productForm.get('categoryId')?.value);
      formData.append('title', this.productForm.get('title')?.value);
      formData.append('price', this.productForm.get('price')?.value);
      formData.append('description', this.productForm.get('description')?.value);

      const formValues = this.productForm.value;
      const selectedAllergens = this.allergenos.map((allergen, index) => ({
        id: allergen.id,
        isSelected: formValues.allergens[index]
      }));

      if(selectedAllergens.length) {
        formData.append('allergens', JSON.stringify(selectedAllergens));  // Ajouter les allergènes avec true/false
      }

      formData.append('lang', this.translationService.getCurrentLanguage());

      if (this.productForm.get('image')?.value) {
        formData.append('image', this.productForm.get('image')?.value);
      }

      // Effectuez la requête POST pour envoyer les données
      this.http.post(environment.apiUrl + '/add-product', formData)
        .pipe(
          catchError(error => {
            console.error('Erreur lors de l\'envoi du produit:', error);
            return of(null);
          })
        )
        .subscribe(response => {
          this.isLoading = false;  // Désactiver le chargement

          if (response) {
            this._snackBar.open("Updated !", '', {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              duration: 2000
            });

            this.signalService.sendSignal('updated');
            this.dialogRef.close(response);
          }
        });
    } else {
      console.log('Formulaire invalide');
    }
  }

  // Méthode d'envoi du formulaire
  onSubmit(): void {
    this.submitProduct();
  }

  generateTextIa(): void {
    this.isLoading = true;
    this.generateTextWithAI().subscribe(
      (response) => {
        if (response && response.responseFromIa) {
          this.isLoading = false;  // Désactiver le chargement après la réponse
          this.productForm.patchValue({
            description: response.responseFromIa // Update the form with the generated text
          });
        } else {
          this.isLoading = false;  // Désactiver le chargement après la réponse
          console.error('Invalid response: No generated text received.');
        }
      },
      (error) => {
        this.isLoading = false;  // Désactiver le chargement après la réponse
        console.error('Error generating text:', error);
      }
    );

    this.generateTitleWithAI().subscribe(
      (response) => {
        if (response && response.responseFromIa) {
          this.isLoading = false;  // Désactiver le chargement après la réponse
          this.productForm.patchValue({
            title: response.responseFromIa // Update the form with the generated text
          });
        } else {
          this.isLoading = false;  // Désactiver le chargement après la réponse
          console.error('Invalid response: No generated text received.');
        }
      },
      (error) => {
        this.isLoading = false;  // Désactiver le chargement après la réponse
        console.error('Error generating text:', error);
      }
    );
  }

  generateTextWithAI(): Observable<any> {
    const url = `${environment.apiUrl}/generate-text?lang=` + this.translationService.getCurrentLanguage();

    // Get the title and description values from the form
    const descriptionia = this.productForm.get('descriptionia')?.value;

    return this.http.post<any>(url, { descriptionia }).pipe(
      catchError(error => {
        console.error('Error generating text:', error);
        return of(null); // Return null or any appropriate fallback value
      })
    );
  }



  generateTitleWithAI(): Observable<any> {
    const url = `${environment.apiUrl}/generate-title?lang=` + this.translationService.getCurrentLanguage();

    // Get the title and description values from the form
    const descriptionia = this.productForm.get('descriptionia')?.value;

    return this.http.post<any>(url, { descriptionia }).pipe(
      catchError(error => {
        console.error('Error generating text:', error);
        return of(null); // Return null or any appropriate fallback value
      })
    );
  }



  deleteProduct(): void {
    const productId = this.productForm.get('id')?.value;

    if (productId) {
      // Confirm deletion
      const confirmDelete = confirm('Are you sure you want to delete this product?');

      if (confirmDelete) {

        this.isLoading = true;
        // Call the API to delete the product
        this.http.delete(`${environment.apiUrl}/delete-product/${productId}`)
          .pipe(
            catchError(error => {
              // Handle error if deletion fails
              this.isLoading = false;
              console.error('Error deleting product:', error);
              this._snackBar.open('Failed to delete product', '', {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                duration: 2000
              });
              return of(null);
            })
          )
          .subscribe(response => {
            if (response) {
              // Show success message
              this.isLoading = false;
              this._snackBar.open('Product deleted successfully!', '', {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                duration: 2000
              });
              this.signalService.sendSignal('deleted');

              // Close dialog with the response
              this.dialogRef.close('deleted');


            }
          });
      }
    } else {
      this.isLoading = false;
      console.log('Product ID is missing.');
    }
  }

}
