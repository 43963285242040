import { Component, HostListener } from '@angular/core';
import { SplashService} from "../../splash.service";
import { trigger, transition, style, animate } from '@angular/animations';
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs"; // Import correct depuis @angular/animations

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],

})
export class SplashScreenComponent {
  showSplash: boolean = true;
  isVertical: boolean = false;
  logo = environment.logo;
  loading$!: Observable<boolean>;

  constructor(private splashService: SplashService) {
    this.splashService.showSplash$.subscribe(show => {
      this.showSplash = show;
    });
    this.checkOrientation();
  }

  // Vérifier l'orientation de l'écran
  checkOrientation(): void {
    this.isVertical = window.innerHeight > window.innerWidth;
  }

  // Listener pour ajuster l'orientation lors du redimensionnement de l'écran
  client = environment.client;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkOrientation();
  }
}
