<app-splash-screen *ngIf="showSplash"></app-splash-screen>

<mat-sidenav-container
  fullscreen
  autosize
  [hasBackdrop]="false"
  *ngIf="!showSplash"
  >

  <mat-toolbar class="fixednavbar" color="primary">

    <a href="#" style="display: none;"></a>

    <button mat-icon-button (click)="drawer.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span></span>
    <span class="example-spacer"></span>

    <!--
    <a (click)="drawercart.toggle()" *ngIf="hasItems | async" [ngClass]="{ 'pink' : hasItems | async }" mat-icon-button
       class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </a>
    -->


    <button (click)="navigation.back()" mat-icon-button class="example-icon"
            aria-label="Example icon-button with share icon">
      <mat-icon>cached</mat-icon>
    </button>
  </mat-toolbar>
  <mat-sidenav (click)="onSidebarClick($event)" #drawer mode="over" class="sidebar" [fixedInViewport]="options.value.fixed" [fixedTopGap]="options.value.top" [fixedBottomGap]="options.value.bottom">
    <ul>
      <button (click)="drawer.toggle()" class="closeRight">
        <mat-icon aria-hidden="false" aria-label="Fermer" fontIcon="close"></mat-icon>
      </button>

      <li class="homeLink"><a routerLink="" (click)="drawer.toggle()">
        {{ 'Home' | translate }}</a></li>

      <li *ngFor="let category of obj">
        <a mat-list-item routerLink="browse/categories/{{category.id}}" *ngIf="category.id != 507"
           (click)="drawer.toggle()">{{ category.name }}</a>
      </li>


      <li style="margin-top:40px;" *ngIf="menuPdf"><a target="_blank" href="{{menuUrl}}/menu" >Menu pdf</a></li>


      <!--
      <li style="margin-top:40px;"><a routerLink="#"   (click)="drawercart.toggle()"  >{{ 'My_favorites' | translate }}</a></li>
      -->
      <div *ngIf="showInstallButton">
        <a mat-list-item (click)="installPWA()" routerLink="#"
           (click)="drawer.toggle()">{{ 'install_txt' | translate }}</a>
      </div>

    </ul>

  </mat-sidenav>


  <mat-sidenav #drawercart   mode="side" position="end" class="sidebarright" [fixedInViewport]="options.value.fixed" [fixedTopGap]="options.value.top" [fixedBottomGap]="options.value.bottom">
    <div class="position-relative" style="max-width: 180px; position: relative">
      <div class="topCart">
        <h3>
          <mat-icon class="pink">favorite</mat-icon> {{ 'My_favorites' | translate }}
        </h3>
      </div>

      <button (click)="drawercart.toggle()" #closeright class="closeRight">
        <mat-icon aria-hidden="false" aria-label="Fermer" fontIcon="close"></mat-icon>
      </button>

      <button  (click)="confirmDialogDeleteAll();"  class="deleteBtn">
        <mat-icon aria-hidden="false" fontIcon="delete"></mat-icon>
      </button>

      <app-cart></app-cart>
    </div>
  </mat-sidenav>


  <div>


    <div class="main-content" [@fadeAnimation]="outlet.isActivated ? outlet.activatedRoute : ''">

      <header class="header">
        <a routerLink="/"><img src="assets/{{client}}/logo.svg" width="300" class="logomain"/></a>
      </header>


      <div class="contentapp">

      <div class="language-selector">
        <span *ngFor="let lang of availableLanguages"
              (click)="changeLanguage(lang)"
              [class.active]="lang === currentLanguage">
          <img src="/assets/flags/{{ lang }}.svg" class="flag" />
        </span>
      </div>




      <router-outlet #outlet="outlet"></router-outlet>

      </div>

    </div>
  </div>

</mat-sidenav-container>
