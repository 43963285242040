
<mat-spinner *ngIf="(loading$ | async); else showContent" [diameter]="80" [strokeWidth]="3"></mat-spinner>

<mat-drawer #drawer class="example-sidenav" mode="side" >
  <h2 (click)="drawer.toggle()">Close</h2>
  <pre>{{ product | json}}</pre>
</mat-drawer>




<ng-template #showContent>
  <div  *ngFor="let image of product.images">
  <div *ngIf="isFullscreen" class="zoom-container fullscreen-modal" (click)="closeFullscreen()">

    <img
      src="{{ image.image1200Url }}"
      class="fullscreen-image"
      (pinch)="onPinchZoom($event)"

      [ngStyle]="{
        transform: 'scale(' + zoomScale + ') translate(' + translateX + 'px, ' + translateY + 'px)'
      }"
      (wheel)="zoomImage2($event)"

    />
  </div>
  </div>

  <!-- This for autofocus -->
  <a href=""></a>

  <div class="arrow-navigation">
    <!-- Flèche gauche -->
    <button (click)="changeProduct(-1)" class="arrow-left" [disabled]="isFirstProduct()">
      <mat-icon class="arrow-icon">keyboard_arrow_left</mat-icon>
    </button>

    <!-- Flèche droite -->
    <button (click)="changeProduct(1)" class="arrow-right" [disabled]="isLastProduct()">
      <mat-icon class="arrow-icon">keyboard_arrow_right</mat-icon>
    </button>
  </div>



  <button class="closeIconBtn" (click)="activeModal.dismiss('Cross click')">
    <mat-icon  class="closeIcon">close</mat-icon>
  </button>

  <div class="appModalContainer"  (click)="handleClick()">
    <div class="container-modal">
      <div class="row {{flex}}">
        <div class="col-sm-12 col-md-12 col-lg-8 order-2 order-lg-1">
          <div style="position: relative">
          <swiper
            class="mySwiper2"
            style="--swiper-navigation-color: #fff;--swiper-pagination-color: #fff"
            [direction]="'horizontal'"
            [spaceBetween]="0"
            [rewind]="false"
            [zoom]="true"
            [lazy]="false"
            [navigation]="true"
            [pagination]="{ clickable: true }"
          >
            <ng-template swiperSlide *ngFor="let image of product.images">

              <!-- Image cliquable -->
              <div class="zoom-container" (click)="openFullscreen()">
                <img
                  #zoomImage
                  width="100%"
                  height="auto"
                  class="item"
                  *ngIf="product.default_image"
                  [lazyLoad]="image.image800Url"
                  [defaultImage]="'assets/images/loading.gif'"
                  [errorImage]="errorImage"
                  alt="Product Image"
                />
              </div>
            </ng-template>

          </swiper>



            <!--
            <button *ngIf="!isInMyFavorite(product)" class="addToFavorites" (click)="addToCart(product)" >
              <img src="assets/images/like.svg" width="40"/>
            </button>

            <button *ngIf="isInMyFavorite(product)" class="addToFavorites" (click)="addToCart(product)" >
              <img src="assets/images/unlike.svg" width="40"/>
            </button>
            -->

            <div class="edit-container" *ngIf='(session.isLoggedIn$ | async)'>
              <button (click)="openProductDialog(product.defaultCategorieId, product)" mat-raised-button color="secondary">Edit</button>
            </div>
          </div>
        </div>

        <!-- Colonne Description (sera affichée en deuxième sur mobile) -->
        <div class="col-sm-12 col-md-12  col-lg-4 order-1 order-lg-2" style="position: relative; text-align: left;">
          <div class="verticalContainer">
            <div class="content">
              <h1 [innerHTML]="text(product?.name)">{{product?.name}}</h1>
              <h2 [innerHTML]="text(product?.shortDescription)"> {{product?.shortDescription}}</h2>
              <div [innerHTML]="html(product?.description)"></div>

              <div class="allergens-container">
                <div *ngFor="let allergen of product?.alergens">
                  <img
                    src="{{ allergen.iconUrl }}"
                    mat-icon-button
                    matTooltip="{{ allergen.name }}"
                    matTooltipShowDelay="500"
                  matTooltipTouch="true"
                  class="round-icon"
                  width="50"
                  />
                </div>

              </div>



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>



