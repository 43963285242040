import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild, AfterViewInit
} from '@angular/core';
import {CategoryModel} from "../../models/category.model";
import {Observable} from "rxjs";
import {CategoriesService} from "../../services/categories.service";
import {ActivatedRoute, Router} from "@angular/router";
import {switchMap, take, tap} from "rxjs/operators";
import {ProductsService} from "../../services/products.service";
import {ProductModel} from "../../models/product.model";
import {NgxMasonryComponent, NgxMasonryOptions} from 'ngx-masonry';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import {LightGallery} from 'lightgallery/lightgallery';
import {FormBuilder, FormGroup, FormControl, Validators, FormArray} from '@angular/forms';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductDetailsComponent} from "../product-details/product-details.component";
import { NavigationService} from "../../../core/services/navigation.service";
import {SessionLoginService} from "../../../core/services/session-login.service";
import { filter, map } from 'rxjs/operators';
import {of} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {ProductDialogComponent} from "../admin/product-dialog/product-dialog.component";
import {SignalService} from "../../services/signal.service";
import {Subscription} from "rxjs";
import {LanguageChangeService} from "../../services/language-change.service";
import {environment} from "../../../../environments/environment.prod";
import {TranslationService} from "../../services/translation.service";
import {TranslateService} from "@ngx-translate/core";
import {CategoryDialogComponent} from "../admin/category-dialog/category-dialog.component";
import {Subject} from "rxjs";

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-category-detail',
  templateUrl: './category-detail.component.html',
  styleUrls: ['./category-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,

})



export class CategoryDetailComponent implements OnInit, AfterViewInit {
  closeResult!: string;
  @ViewChild(NgxMasonryComponent) masonry!: NgxMasonryComponent;

  loading$!: Observable<boolean>;
  categoryIn$!: Observable<CategoryModel>;
  categorySubscription!: Subscription;

  products$!: Observable<ProductModel[]>;

  id!: string;
  baselang!: string;

  product!: ProductModel[];

  form: FormGroup;

  receivedMessage: string = '';
  private subscription!: Subscription;



  showProduct = '';
  constructor(
    public translate: TranslateService,
    public translationService: TranslationService,
    private languageChangeService: LanguageChangeService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private categoriesService: CategoriesService,
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService,
    public navigation: NavigationService,
    public session: SessionLoginService,
    public dialog: MatDialog,
    private signalService: SignalService
  ) {

    let lang = localStorage.getItem('selectedLanguage');
    if(lang) {
      this.translate.use(lang);
    }

    this.baselang = environment.lang;

    this.form = this.formBuilder.group({
      setTo: this.formBuilder.array([], [Validators.required])
    })

    this.products$ = this.productsService.products$;

    this.route.paramMap.subscribe(params => {
      this.ngOnInit();
    });


    let firstLoad = true;
    if (this.route.snapshot.queryParams['showProduct']) {
      this.route.queryParams.pipe(
        filter(params => !!params['showProduct']), // Filtrez pour s'assurer que showProduct est présent
        map(params => parseInt(params['showProduct'], 10)), // Convertissez le showProduct en nombre
        filter(productId => !!productId),
      ).subscribe(productId => {
        this.products$
          .pipe(
            map(products => products.find(product => product.id === productId)),
            filter(product => !!product)
          )
          .subscribe(product => {
            if (product && productId) {
              if(firstLoad) {
                this.open(product);
              }
            }
            firstLoad = false;

          });
      });
    } else {
      firstLoad = false;
    }

  }

  private modalRef!: any

  back(): void {
    this.navigation.back()
  }

  open(product: ProductModel) {

    let id = parseInt('' + this.route.snapshot.paramMap.get('id'));
    //this.productsService.getProductsFromServer(id).subscribe();

    //this.getData(id);
    this.iniObservable();

    this.setBackgroundImageDetail(id);

    this.modalRef = this.modalService.open(ProductDetailsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      fullscreen: true,
      keyboard: true,
      backdrop: 'static',
    });

    this.modalRef.componentInstance.productIds = this.productIds;

    const url = this.router.createUrlTree([], {relativeTo: this.route, queryParams: {showProduct: product.id}}).toString()
    this.router.navigateByUrl(url, { skipLocationChange: false});

    this.modalRef. result.then(() => { alert('When user closes'); }, () => {
      this.router.navigate([], { relativeTo: this.route, queryParams: { showProduct: null }, queryParamsHandling: 'merge' });
    })

    return this.modalRef.componentInstance.product = product;
  }


  ngOnDestroy(): void {
    if (this.categorySubscription) {
      this.categorySubscription.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();

    this.productsService.cleanProducts();
  }

  background: any;
  productIds: any;

  ngOnInit(): void {

    /*
          this.languageChangeService.currentLanguage$.subscribe((lang) => {
            this.loading$ = this.productsService.loading$;

            let id = parseInt('' + this.route.snapshot.paramMap.get('id'));
            this.productsService.getProductsFromServer(id).subscribe();
            localStorage.setItem('lastId', JSON.stringify(id));

            this.products$.pipe(
              map(products => products)
            ).subscribe(products => {
              this.productIds = products;
            });

          });

           */

    this.subscription = this.signalService.signal$.subscribe(message => {
      if(message) {
        this.loading$ = this.productsService.loading$;
        this.productsService.getProductsFromServer(id).subscribe();
      }
    });


    //this.isAuthentified = this.session.isLoggedIn().subscribe();

    this.loading$ = this.productsService.loading$;

    let id = parseInt('' + this.route.snapshot.paramMap.get('id'));
    this.productsService.getProductsFromServer(id).subscribe();
    localStorage.setItem('lastId', JSON.stringify(id));

    //this.getData(id);
    this.iniObservable();

    this.setBackgroundImage(id);

    this.products$.pipe(
      map(products => products)
    ).subscribe(products => {
      this.productIds = products;
    });


  }

  ngAfterViewInit(): void {

  }

  getImageUrl(imageUrl: any): string {
    // Extrait le chemin de l'URL de l'image sans la partie de la taille (ex: "300-300")
    const baseUrl = imageUrl.split('/images/')[0] + '/images/';
    const imageName = imageUrl.split('/images/')[1];

    // Crée la nouvelle URL avec les dimensions du screen
    return `${baseUrl}${window.screen.width}-${window.screen.height}/images/${imageName}`;
  }
  private destroy$ = new Subject<void>();

  private setBackgroundImage(id: number) {

    let have = false;

    const loadCategoryBackground = () => {
      this.categoryIn$.pipe(take(1)).subscribe(category => {
        if (category?.bgh) {
          have = true;
          const bgh = category.bgh;
          const bgv = category.bgv;

          const screenWidth = window.screen.width;
          const screenHeight = window.screen.height;

          // Set background image
          let background = this.getImageUrl(bgh);

          if (window.innerHeight > window.innerWidth) {
            background = this.getImageUrl(bgv);
          }

          var myCss = ".mat-drawer-container { background:url('" + background + "') no-repeat center center fixed; }";
          var style = document.createElement("STYLE");
          style.innerText = myCss;
          document.body.appendChild(style);
        } else {
          // If 'bgh' is not available, retry
          console.log('bgh not found, retrying...');
          setTimeout(loadCategoryBackground, 1000); // Relance après 1 seconde
        }
      });
    };

// Appel initial pour charger l'image
    loadCategoryBackground();

  }

  private setBackgroundImageDetail(id: number) {

    let have = false;

    const loadCategoryBackground = () => {

      this.categoryIn$.pipe(take(1)).subscribe(category => {
        if (category?.bgh) {
          have = true;
          const bgh = category.bgh;
          const bgv = category.bgv;

          const screenWidth = window.screen.width;
          const screenHeight = window.screen.height;

          // Set background image
          let background = this.getImageUrl(bgh);

          if (window.innerHeight > window.innerWidth) {
            background = this.getImageUrl(bgv);
          }

          var myCss = ".mat-drawer-container { background:url('" + background + "') no-repeat center center fixed; }";
          var style = document.createElement("STYLE");
          style.innerText = myCss;
          document.body.appendChild(style);
        } else {
          // If 'bgh' is not available, retry
          console.log('bgh not found, retrying...');
          setTimeout(loadCategoryBackground, 1000); // Relance après 1 seconde
        }
      });
    };

  }


  private iniObservable() {
    this.categoryIn$ = this.route.params.pipe(
      switchMap(params => this.categoriesService.getCategoryById(+params['id']))
    );

  }


  onResize(event: any) {
    let id = parseInt('' + this.route.snapshot.paramMap.get('id'));
    this.setBackgroundImage(id);
  }

  onHire() {
    this.categoryIn$.pipe(
      take(1),
      tap(category => {
        this.categoriesService.changeNameToCategory(category.id);
        this.onGoBack();
      })
    ).subscribe();
  }

  onDelete() {

    this.categoryIn$.pipe(
      take(1),
      tap(category => {
        this.categoriesService.deleteCategory(category.id);
        this.onGoBack()
      })
    ).subscribe();
  }

  onGoBack() {
    this.router.navigateByUrl('/browse')
  }


  openDialog(enterAnimationDuration: string, exitAnimationDuration: string, productId: number): void {
    //let product = this.productsService.getProductById(enterAnimationDuration, exitAnimationDuration, productId).subscribe();
  }

  items: any[] = [];


  private lightGallery!: LightGallery;
  private needRefresh = false;

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      this.lightGallery.refresh(this.items);
      this.lightGallery.openGallery();
      this.needRefresh = false;
    }
  }

  settings = {
    counter: false,
    dynamic: true,
    dynamicEl: this.items,
    preload: 3,
    plugins: [
      lgThumbnail
    ]
  };


  onInit = (detail: any): void => {
    this.lightGallery = detail.instance;
  };

  public myOptions: NgxMasonryOptions = {
    gutter: 0,
    resize: true,
    horizontalOrder: false,
    percentPosition:true,
    originLeft:true,
  };
  filmIcon: any;

  openGalery(product: any) {
    this.items = [];
    for (let result of product.images) {

      let newItem = {
        id: result.id,
        src: result.imageUrl,
        thumb: result.imageUrl,
        subHtml: `<div class="lightGallery-captions">
            <h4>` + product.name + `</h4>
        </div>`
      }
      this.items.push(newItem);
    }
    this.needRefresh = true;
  }


  onCheckboxChange(e: any) {
    const setTo: FormArray = this.form.get('setTo') as FormArray;

    if (e.target.checked) {
      let value = new FormControl(e.target.value);
      //alert(e.target.value);

      setTo.push(new FormControl(e.target.value));
    }
  }


  submit() {
    console.log(this.form.value);
  }

  clickCount: number = 0;
  delayBetweenClicks: number = 500; // 500 millisecondes de délai entre les clics
  lastClickTime: number = 0;
  showSearch: boolean = false;


  handleClick() {
    const currentTime = Date.now();

    if (currentTime - this.lastClickTime <= this.delayBetweenClicks) {
      this.clickCount++;
    } else {
      this.clickCount = 1;
    }

    if (this.clickCount === 5) {

      this.showSearch = !this.showSearch;
      this.clickCount = 0;
    }

    this.lastClickTime = currentTime;
  }

  searchReference: string = '';

  handleSearchChange() {
    if (this.searchReference.trim() !== '') {
      this.productsService.filterProductsByReference(this.searchReference.trim()).subscribe(filteredProducts => {
        this.products$ = of(filteredProducts);
      });
    } else {
      this.products$ = this.productsService.products$;
    }
  }

  openProductDialog(id:any): void {
    const dialogRef = this.dialog.open(ProductDialogComponent, {
      width: '800px',
      data: {
        categoryId:id
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.ngOnInit();
      }
    });
  }

  openCategoryDialogDialog(category: any) {
    const dialogRef = this.dialog.open(CategoryDialogComponent, {
      width: '800px',
      data: {
        category:category
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        location.reload();
      }
    });
  }
}
