import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductsCategoriesRoutingModule } from './products-categories-routing.module';
import { CategoriesListComponent } from './components/categories-list/categories-list.component';
import { CategoryDetailComponent } from './components/category-detail/category-detail.component';
import {SharedModule} from "../shared/shared.module";
import {CategoriesService} from "./services/categories.service";
import {ProductsService} from "./services/products.service";
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import {LightgalleryModule} from "lightgallery/angular";
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from "ng-lazyload-image";
import {NgxMasonryModule} from "ngx-masonry";
import {DndModule} from "ngx-drag-drop";
import { ProductsAdminComponent } from './components/products-admin/products-admin.component';
import {SwiperModule} from "swiper/angular";
import {NgMagnizoomModule} from "ng-magnizoom";
import { BrowserModule } from '@angular/platform-browser';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { SubCategoriesListComponent } from './components/sub-categories-list/sub-categories-list.component';
import { ProductDialogComponent } from './components/admin/product-dialog/product-dialog.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { CategoryDialogComponent } from './components/admin/category-dialog/category-dialog.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { ScreensaverComponent } from './screensaver/screensaver.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    CategoriesListComponent,
    CategoryDetailComponent,
    ProductDetailsComponent,
    ProductsAdminComponent,
    SubCategoriesListComponent,
    ProductDialogComponent,
    SplashScreenComponent,
    CategoryDialogComponent,
    ScreensaverComponent,


  ],
  imports: [
    CommonModule,
    SharedModule,
    ProductsCategoriesRoutingModule,
    LightgalleryModule,
    LazyLoadImageModule,
    NgxMasonryModule,
    DndModule,
    SwiperModule,
    NgMagnizoomModule,
    NgxImageZoomModule,
    MatTooltipModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

  ],
    exports: [
        ProductDetailsComponent,
        SplashScreenComponent,
        ScreensaverComponent,

    ],
  providers: [
    CategoriesService,
    ProductsService,
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }
  ]
})
export class ProductsCategoriesModule { }
