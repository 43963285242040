<mat-spinner *ngIf="(loading$ | async); else showContent" [diameter]="80" [strokeWidth]="3"></mat-spinner>

<ng-template #showContent>

  <div  [ngClass]="categoryId ? 'appContainer1 centerVertical' : 'appContainer1'">


    <div class="row rowcategoryList"
       *ngIf="categories$ | async as categories" (window:resize)="onResize($event)">


      <div *ngIf='(session.isLoggedIn$ | async) && baselang == translationService.getCurrentLanguage()' style="margin-bottom: 20px;">


        <button (click)="openCategoryDialogDialog()" mat-raised-button color="secondary" style="margin-right:10px;" >Nueva categoria</button>


      </div>

      <ng-container *ngFor="let category of categories">
        <div class="{{ category.isSunday ? 'col-12' : 'col-6 col-sm-4 col-md-4' }}  categoryList">
          <a routerLink="/categories/{{category.id.toString()}}">


            <img
              [alt]="category.name"
              [src]="category.image300Url"
              [lazyLoad]="category.image300Url"
              *ngIf="category.image300Url && !category.isSunday"
              [debug]="false"
              [offset]="100"
              src="assets/images/svg-loaders/tail-spin.svg"
            />

            <img
              [alt]="category.name"
              [src]="category.largeImage"
              [lazyLoad]="category.largeImage"
              *ngIf="category.largeImage && category.isSunday"
              [debug]="false"
              [offset]="100"
              src="assets/images/svg-loaders/tail-spin.svg"
            />
            <div class="categoryName">
              {{ category.name }}
            </div>

            <div *ngIf="category.isSunday" class="sep"> </div>
          </a>
        </div>
      </ng-container>

    </div>

    <div class="copyright" style="color:#fff; font-size: 11px; text-align: center; margin-top: 20px;">
      Coded and designed with 🤍 by<br />
      <a style="color:#fff;" href="mailto:antho.brioit@gmail.com">Anthony Brioit</a>
    </div>


  </div>

</ng-template>
