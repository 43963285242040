
<mat-spinner *ngIf="(loading$ | async); else showContent" [diameter]="80" [strokeWidth]="3"></mat-spinner>
<ng-template #showContent>



  <div *ngIf="categoryIn$ | async as category"  (window:resize)="onResize($event)">
    <div class="appContainer2">

      <a routerLink="/" mat-button  class="home-button">
        <mat-icon>home</mat-icon>
      </a>

      <div class="h1Container no-select"  (click)="handleClick()">
        <h1>{{ category.name }}</h1>


      </div>




      <div *ngIf='(session.isLoggedIn$ | async)' style="margin-bottom: 20px;">
        <button (click)="openProductDialog(category.id)" mat-raised-button color="secondary" style="margin-right:10px;" >{{ 'add_product' | translate }}</button>
        <a  *ngIf='session.isLoggedIn$ | async' routerLink="/admin/categories/{{category.id}}" mat-raised-button color="secondary"  style="margin-right:10px;"><mat-icon aria-hidden="false" aria-label="Fermer" fontIcon="low_priority" class="pink"></mat-icon> Order</a>


        <button (click)="openCategoryDialogDialog(category)" mat-raised-button color="secondary" style="margin-right:10px;" >{{ 'category_details' | translate }}</button>


      </div>

      <div *ngIf="showSearch" style="margin-top:30px; text-align: center; border: solid 1px #fff; margin-bottom: 20px; padding: 40px 0 20px 0;">
        <h1>search <input type="text" [(ngModel)]="searchReference" (ngModelChange)="handleSearchChange()" style="border: none; background-color: #fff; padding: 5px;">
        </h1>
      </div>


      <div *ngIf="(products$ | async) as products" class="productList">
        <div *ngIf="products.length == 0" class="noResults">
          Not found<br/><br/>
        </div>

        <mat-grid-list cols="3" class="grille" [gutterSize]="'15px'" rowHeight="2:2">

          <mat-grid-tile
            *ngFor="let product of products; let i = index"

            (click)="open(product)"
            [colspan]="((i%6 == 3 && i != 0) || (i%12 == 7 && i != 0)) ? 2 : 1"
            [rowspan]="((i%6 == 3 && i != 0) || (i%12 == 7 && i != 0)) ? 2 : 1"
          >


            <img
              *ngIf="product.default_image"
              [src]="((i % 6 === 3 && i !== 0) || (i % 12 === 7 && i !== 0)) ? product.default_image.image800Url : product.default_image.image300Url"
              width="100%"
              alt="{{product.name}}"
            />

            <span style="color: #fff" *ngIf="!product.default_image">{{product.id}}</span>
            <div class="product-price">{{product.shortDescription}}</div>
            <div class="product-title">{{product.name}}</div>
          </mat-grid-tile>
        </mat-grid-list>

        <div style="color:#fff; font-size: 11px; text-align: center; margin-top: 20px;" class="copyright">
          Coded and designed with 🤍 by<br />
          <a style="color:#fff;" href="mailto:antho.brioit@gmail.com">Anthony Brioit</a>
        </div>

      </div>
    </div>
  </div>


</ng-template>

<div class="session-modal-container"></div>

