import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'; // Assurez-vous d'importer TranslateService

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private availableLanguages: string[] = ['es', 'en', 'fr', 'de', 'nl'];

  constructor(private translate: TranslateService) {
    // Charger la langue sauvegardée depuis localStorage ou utiliser 'en' par défaut
    const savedLang = localStorage.getItem('selectedLanguage') || 'es';
    this.setLanguage(savedLang);
  }

  // Méthode pour récupérer les langues disponibles
  getAvailableLanguages(): string[] {
    return this.availableLanguages;
  }

  // Méthode pour définir une nouvelle langue
  setLanguage(lang: string): void {
    if (this.availableLanguages.includes(lang)) {
      this.translate.use(lang); // Changer la langue avec ngx-translate
      localStorage.setItem('selectedLanguage', lang); // Sauvegarder la langue dans localStorage
    }
  }

  // Méthode pour récupérer la langue actuellement sélectionnée
  getCurrentLanguage(): string {
    return localStorage.getItem('selectedLanguage') || 'es';
  }
}
