import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { environment } from "../../../../../environments/environment";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {SignalService} from "../../../services/signal.service";
import {Observable} from "rxjs";
import {TranslationService} from "../../../services/translation.service";  // Import MatDialogRef

@Component({
  selector: 'app-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.scss']
})
export class CategoryDialogComponent implements OnInit {


  productForm!: FormGroup;
  isLoading = false;
  // Injecter MatDialogRef pour fermer la boîte de dialogue
  constructor(
    private translationService: TranslationService,
    private _snackBar: MatSnackBar,
    private signalService: SignalService,
    private fb: FormBuilder, private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CategoryDialogComponent>) { }

  ngOnInit(): void {
    let categoryId = this.data?.categoryId;
    let category = this.data?.category;

    // Initialiser le formulaire
    this.productForm = this.fb.group({
      id: [null],
      title: ['', [Validators.required]],
      position: ['', [Validators.required]],
      image: [null, category ? [] : [Validators.required]],
      bgh: [null, category ? [] : [Validators.required]],
      bgv: [null, category ? [] : [Validators.required]]
    });

    // Si une catégorie existe, patcher les valeurs
    if (category) {
      this.productForm.patchValue({
        id: category.id,
        title: category.name,
        position: category.position,
      });
    }
  }


  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.productForm.patchValue({
        image: file
      });
    }
  }


  onFileChangeh(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.productForm.patchValue({
        bgh: file
      });
    }
  }

  onFileChangev(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.productForm.patchValue({
        bgv: file
      });
    }
  }


  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  // Envoi des données vers le backend
  submitProduct(): void {
    if (this.productForm.valid) {
      this.isLoading = true;  // Activer le chargement

      const formData = new FormData();
      if (this.productForm.get('id')?.value) {
        formData.append('id', this.productForm.get('id')?.value);
      }

      formData.append('title', this.productForm.get('title')?.value);
      formData.append('position', this.productForm.get('position')?.value);
      formData.append('lang', this.translationService.getCurrentLanguage());

      if (this.productForm.get('image')?.value) {
        formData.append('image', this.productForm.get('image')?.value);
      }

      if (this.productForm.get('bgh')?.value) {
        formData.append('bgh', this.productForm.get('bgh')?.value);
      }

      if (this.productForm.get('bgv')?.value) {
        formData.append('bgv', this.productForm.get('bgv')?.value);
      }

      // Effectuez la requête POST pour envoyer les données
      this.http.post(environment.apiUrl + '/add-category', formData)
        .pipe(
          catchError(error => {
            console.error('Erreur lors de l\'envoi du produit:', error);
            return of(null);
          })
        )
        .subscribe(response => {
          this.isLoading = false;  // Désactiver le chargement

          if (response) {
            this._snackBar.open("Updated !", '', {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              duration: 2000
            });

            this.signalService.sendSignal('updated');
            this.dialogRef.close(response);
          }
        });
    } else {
      console.log('Formulaire invalide');
    }
  }

  // Méthode d'envoi du formulaire
  onSubmit(): void {
    this.submitProduct();
  }





  deleteProduct(): void {
    const productId = this.productForm.get('id')?.value;

    if (productId) {
      // Confirm deletion
      const confirmDelete = confirm('Are you sure you want to delete this product?');

      if (confirmDelete) {

        this.isLoading = true;
        // Call the API to delete the product
        this.http.delete(`${environment.apiUrl}/delete-category/${productId}`)
          .pipe(
            catchError(error => {
              // Handle error if deletion fails
              this.isLoading = false;
              console.error('Error deleting product:', error);
              this._snackBar.open('Failed to delete product', '', {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                duration: 2000
              });

              return of(null);
            })
          )
          .subscribe(response => {
            if (response) {
              // Show success message
              this.isLoading = false;
              this._snackBar.open('Product deleted successfully!', '', {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                duration: 2000
              });
              this.signalService.sendSignal('deleted');

              // Close dialog with the response
              this.dialogRef.close('deleted');
              window.location.replace('/');


            }
          });
      }
    } else {
      this.isLoading = false;
      console.log('Product ID is missing.');
    }
  }
}
