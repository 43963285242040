<div style="max-width: 60vw; margin:auto;">
  <div class="scrollList">
    <div class="row">

      <div class="col-12">
        <div *ngIf="category$ | async as category">
          <div *ngIf="(products$ | async) as products">
            <div cdkDropList class="example-list"
                 (cdkDropListDropped)="drop($event)"
                 [cdkDropListData]="products"
            >
              <div
                [ngClass]="((i%6 == 3 && i != 0) || (i%12 == 7 && i != 0)) ? 'example-box featured' : 'example-box'"
                *ngFor="let product of products; let i = index"
                cdkDrag
                [cdkDragData]="product"

                data-id="{{product.id}}"
              >
                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                <div class="row">
                  <div class="col-2">
                    <img
                      src="{{ product.default_image.image300Url }}"
                      width="100%"/>
                  </div>
                  <div class="col-10">
                    {{product.id}} - {{product.name}}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>
  </div>

</div>
