import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class getConfService {
  public conf$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public http: HttpClient) {}

  getData() {
    let lang = localStorage.getItem('selectedLanguage');
    const url = `${environment.apiUrl}/getConf.json?lang=${lang}`;

    return this.http.get(url).pipe(
      map((response: any) => {
        this.conf$.next(response);  // Mettre à jour le BehaviorSubject avec les données reçues
        return response;  // Retourner les données pour être utilisées dans le composant
      })
    );
  }
}
