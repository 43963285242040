import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {fadeAnimation} from "./shared/animations/fade.animation";
import {NavigationService} from "./core/services/navigation.service";
import {Router} from "@angular/router";
import {CartComponent} from "./cart/cart.component";
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {CartService} from "./core/services/cart.service";
import {FormBuilder} from "@angular/forms";
import {LoginComponent} from "./auth/components/login/login.component";
import {MatDialog} from "@angular/material/dialog";
import {SessionLoginService} from "./core/services/session-login.service";
import {Observable, Subscription, BehaviorSubject} from "rxjs";
import {map, take} from "rxjs/operators";
import {DialogComponent} from "./cart/components/dialog/dialog.component";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {MatSidenav} from "@angular/material/sidenav";
import {getConfService} from "./core/services/getConf.service";
import {TranslateService} from "@ngx-translate/core";
import {TranslationService} from "./products-categories/services/translation.service";
import {LanguageChangeService} from "./products-categories/services/language-change.service";
import {SplashService} from "./splash.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation],
})


export class AppComponent implements OnInit, AfterViewInit {
  title = 'menu-app';
  obj!: any
  currentRoute: any;

  @ViewChild('closeright') closeright!: ElementRef;
  @ViewChild('rightSidenav') public sidenav!: MatSidenav;
  @ViewChild('drawercart') public drawercart!: MatSidenav;

  availableLanguages: string[] = [];
  currentLanguage: string = '';


  hasItems: Observable<boolean>;
  subscription!: Subscription;

  options = this._formBuilder.group({
    bottom: 0,
    fixed: true,
    top: 0,
  });

  client = environment.client;


  constructor(
    private translationService: TranslationService,
    private translate: TranslateService,
    private http: HttpClient,
    public navigation: NavigationService,
    private  router: Router,
    private bottomSheet: MatBottomSheet,
    private cartService: CartService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    public session: SessionLoginService,
    public dialogo: MatDialog,
    private _snackBar: MatSnackBar,
    public getConfService: getConfService,
    private languageChangeService: LanguageChangeService,
    private splashService: SplashService
  ) {
    //this.hasItems = this.cartService.itemsInCart.pipe(map(result => !!result));
    this.hasItems = this.cartService.itemsInCartCount$.pipe(map(result => !!result));

    this.availableLanguages = this.translationService.getAvailableLanguages();

    let lang = localStorage.getItem('selectedLanguage');
    if (lang) {
      this.translate.use(lang);
      this.currentLanguage = lang;
    }

    this.splashService.showSplash$.subscribe((show: boolean) => {
      this.showSplash = show;
    });
  }

  appName = environment.appName;
  menuUrl = environment.baseUrl;
  menuPdf = environment.menuPdf;

  theme!: string;
  appUrl!: string;

  ngOnInit(): void {

    localStorage.removeItem('lastId');

    document.title = this.appName;


    this.appUrl = window.location.hostname;
    this.currentRoute = this.router.url;

    let loggedIn = localStorage.getItem('loggedIn');
    let url = environment.baseUrl;
    if (loggedIn == 'true') {
      url = environment.apiUrl
    }

    //Call webservice for get main categories
    this.http.get(`${url}/categories/${environment.mainCategory}?lang=` + this.currentLanguage).subscribe(response => {
      this.obj = response;
    });

    this.cartService.updateCountItems();

    this.getConfService.getData();
    /*
    this.getConfService.conf$.subscribe(data => {
      return this.getConfService.conf$.next(data)
    })
     */


    this.languageChangeService.currentLanguage$.subscribe((lang) => {
      this.currentLanguage = lang;
      this.translate.use(lang);
      localStorage.setItem('selectedLanguage', lang);

    });


  }

  public lastUpdate!: string

  @ViewChild('sidebar') sidebar!: ElementRef;

  sidebarClickListener: any;


  ngAfterViewInit() {
    this.cartService.drawercart = this.drawercart;


  }

  openBottomSheet(): void {
    this.bottomSheet.open(CartComponent);
  }


  openBottomSheetLogin(): void {
    this.clickCount = 0;
    this.dialog.open(LoginComponent, {
      data: {
        wrongCreadentials: null
      }
    });
  }

  logOut(): void {
    this.session.logout()
  }


  confirmDialogDeleteAll(): void {
    this.translate.get('YouAreAboutToClearFavorites').subscribe((message: string) => {
      this.dialogo
        .open(DialogComponent, {
          data: message
        })
        .afterClosed()
        .subscribe((confirmDelete: boolean) => {
          if (confirmDelete) {
            //this.drawercart.close();
            //this.closeright.nativeElement.click();
            this.deleteAll();
          }
        });
    });
  }

  deleteAll() {
    setTimeout(() => {
      this.bottomSheet.dismiss();
      setTimeout(() => {
        this.cartService.clearCart();
        this.openSnackBarDeleteAll();
      }, 500);
    }, 500);

  }


  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  openSnackBarDeleteAll() {
    this._snackBar.open("¡Sus favoritos han sido vaciados con éxito!", '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000
    });
  }


  changeLanguage(lang: string): void {
    this.languageChangeService.changeLanguage(lang);

    location.reload();
  }


  deferredPrompt: any;
  showInstallButton: boolean = false; // Gérer si un bouton d'installation est affiché
  showSplash = true;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeInstallPrompt(event: any) {
    event.preventDefault(); // Empêche l'affichage automatique de la bannière
    this.deferredPrompt = event; // Stocke l'événement pour l'utiliser plus tard

    // Affiche un bouton ou propose l'installation après un délai pour que l'utilisateur ait le temps d'interagir
    this.showInstallButton = true;

  }

  installPWA() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt(); // Affiche la boîte de dialogue d'installation

      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('L\'installation de la PWA a été acceptée');
          // Afficher une notification ou un message après l'installation
          if (Notification.permission === 'granted') {
            new Notification('L\'application est installée. Ouvrez-la maintenant !');
          }
        } else {
          console.log('L\'installation de la PWA a été refusée');
        }
        this.deferredPrompt = null;
        this.showInstallButton = false;
      });
    }
  }

  clickCount = 0;

  clickTimeout: any; // pour stocker le timer

  onSidebarClick(event: MouseEvent): void {
    // Réinitialise le timer à chaque clic
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
    }

    // Incrémente le compteur
    this.clickCount++;

    // Démarre ou redémarre le timer pour réinitialiser le compteur après 500ms
    this.clickTimeout = setTimeout(() => {
      this.clickCount = 0;
    }, 500);

    // Si on atteint 3 clics consécutifs dans la fenêtre de temps
    if (this.clickCount >= 3) {
      // Annule le timer et réinitialise le compteur
      clearTimeout(this.clickTimeout);
      this.clickCount = 0;

      // Vérifie l'état de la session
      this.session.isLoggedIn$.pipe(take(1)).subscribe(isLoggedIn => {
        if (isLoggedIn) {
          this.logOut();
        } else {
          this.openBottomSheetLogin();
        }
      });
    }
  }
}
