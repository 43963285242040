import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from "./core/core.module";
import {SharedModule} from "./shared/shared.module";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthModule} from "./auth/auth.module";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule} from "@angular/common/http";
import {HttpXsrfInterceptorService} from "./core/interceptors/http-xsrf-interceptor/http-xsrf-interceptor.service";
import {ProductsService} from "./products-categories/services/products.service";
import { LightgalleryModule } from 'lightgallery/angular/14';

import {CategoriesService} from "./products-categories/services/categories.service";
import {BackButtonDirective} from "./core/directives/back-button.directive";
import {NavigationService} from "./core/services/navigation.service";
import { CartComponent } from './cart/cart.component';
import { DialogComponent } from './cart/components/dialog/dialog.component';
import { SplashScreenComponent} from "./core/components/splash-screen/splash-screen.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {getConfService} from "./core/services/getConf.service";
import {updateService} from "./core/services/update.service";
import {NgxJsonViewerModule} from "ngx-json-viewer";
import {MatSidenavModule} from "@angular/material/sidenav";
import {CommonModule} from "@angular/common";
import {LazyLoadImageModule} from "ng-lazyload-image";
import {MatDialogModule} from "@angular/material/dialog";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {ProductsCategoriesModule} from "./products-categories/products-categories.module";
import {MatTooltipModule} from "@angular/material/tooltip";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    BackButtonDirective,
    CartComponent,
    DialogComponent,
    SplashScreenComponent,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    AppRoutingModule,
    LightgalleryModule,
    CommonModule,
    CoreModule,
    SharedModule,
    NgbModule,
    AuthModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'token'
    }),
    HammerModule,
    FontAwesomeModule,

    NgxJsonViewerModule,

    MatSidenavModule,
    LazyLoadImageModule,

    MatDialogModule,

    ProductsCategoriesModule,

    MatTooltipModule


  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpXsrfInterceptorService, multi: true},
    ProductsService,
    CategoriesService,
    NavigationService,
    getConfService,
    updateService
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {







}
