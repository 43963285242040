<div style="background-color: #fff; padding:20px; 0 20px 0">
  <h1 mat-dialog-title style="color:#000;">Categoria</h1>
  <div mat-dialog-content>

    <div *ngIf="isLoading" class="loading-overlay">
      <mat-spinner></mat-spinner>
    </div>

    <form [formGroup]="productForm" (ngSubmit)="onSubmit()">

      <!-- Champ Titre -->
      <div class="form-group">
        <label for="title">Titulo*</label>
        <input id="title" type="text" class="form-control" formControlName="title" placeholder="Titulo" />
      </div>

      <!-- Champ Prix -->
      <div class="form-group">
        <label for="position">Posicion en la lista</label>
        <input id="position" type="number" class="form-control"  formControlName="position" placeholder="10" />
      </div>




      <!-- Champ Image -->
      <div class="form-group">
        <label for="image">Imagen de categoria</label>
        <input id="image" type="file" class="form-control" formControlName="image" (change)="onFileChange($event)" />

      </div>



      <!-- Champ Image -->
      <div class="form-group">
        <label for="bgh" >Imagen de fondo horizontal</label>
        <input id="bgh" type="file" class="form-control" formControlName="bgh" (change)="onFileChangeh($event)" />

      </div>



      <!-- Champ Image -->
      <div class="form-group">
        <label for="bgv">Imagen de fondo vertical</label>
        <input id="bgv" type="file" class="form-control" formControlName="bgv" (change)="onFileChangev($event)" />

      </div>

    </form>
  </div>


  <!-- Footer avec bouton -->
  <div mat-dialog-actions style="justify-content: flex-end; padding-right:20px; margin-bottom:0;" >
    <button mat-raised-button color="secondary" type="button" *ngIf="productForm?.get('id')?.value" (click)="deleteProduct()">
      Eliminar
    </button>


    <button mat-raised-button color="primary" type="button" [disabled]="productForm?.invalid" (click)="onSubmit()">
      Actualizar
    </button>
  </div>
</div>
