
export const environment = {
  production: true,

  //Define all url
  apiUrl: 'https://api.dondelatia.com/api',
  baseUrl: 'https://api.dondelatia.com',
  baseLoginUrl: 'https://api.dondelatia.com/api',

  //Fet default category
  mainCategory: '0',
  lang: 'es',
  client: 'dondelatia',
  logo: 'assets/dondelatia/logo.png',
  appName: 'Donde La tia',
  menuPdf: false

};
