import { NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {CategoriesListComponent} from "./components/categories-list/categories-list.component";
import {CategoryDetailComponent} from "./components/category-detail/category-detail.component";
import {AuthGuard} from "../core/guards/auth.guard";
import {ProfileService} from "../core/services/profile.service";
import {ProductsAdminComponent} from "./components/products-admin/products-admin.component";
import {ProductDetailsComponent} from "./components/product-details/product-details.component";
import {SubCategoriesListComponent} from "./components/sub-categories-list/sub-categories-list.component";

/*
const routes: Routes = [
  { path: 'categories', component: CategoriesListComponent, canActivate:[ProfileService]},
  { path: 'categories/:id', component: CategoryDetailComponent, canActivate:[ProfileService]},
  { path : '', pathMatch: 'full', redirectTo:'categories'}
];
*/

const routes: Routes = [
  { path: 'categories', component: CategoriesListComponent},


  { path: 'categories/:id', component: CategoryDetailComponent},

  { path: 'categories/products/:id/:productId', component: ProductDetailsComponent},

  { path: 'admin/categories/:id', component: ProductsAdminComponent, canActivate: [AuthGuard] },

  { path : '', pathMatch: 'full', redirectTo:'categories'}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsCategoriesRoutingModule { }
