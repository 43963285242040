import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SplashService {
  private showSplashSubject = new BehaviorSubject<boolean>(true);
  showSplash$ = this.showSplashSubject.asObservable();

  constructor() {
    // Masquer le splash screen après 3 secondes
    setTimeout(() => {
      this.showSplashSubject.next(false);
    }, 3000); // 3000 ms = 3 secondes
  }
}
