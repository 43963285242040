<div style="background-color: #fff; padding:20px; 0 20px 0">
  <h1 mat-dialog-title style="color:#000;">Agregar un producto</h1>
  <div mat-dialog-content>

    <div *ngIf="isLoading" class="loading-overlay">
      <mat-spinner></mat-spinner>
    </div>

    <form [formGroup]="productForm" (ngSubmit)="onSubmit()">

      <!-- Champ IA -->
      <div class="form-group" style="text-align:left; padding: 25px; background-color: #ececec; border: solid 1px #666;">
        <label for="descriptionia" style="color:#000;">Inteligencia artificial*</label>
        <textarea id="descriptionia" class="form-control" rows="3" formControlName="descriptionia" placeholder="Pídele a la IA que genere el contenido"></textarea>
        <button mat-raised-button color="warning" type="button" style="margin-top: 10px;" (click)="generateTextIa()">
          Generar con IA
        </button>
      </div>





      <!-- Champ Titre -->
      <div class="form-group">
        <label for="title">Titulo*</label>
        <input id="title" type="text" class="form-control" formControlName="title" placeholder="Titulo" />
      </div>

      <!-- Champ Prix -->
      <div class="form-group">
        <label for="price">Precio</label>
        <input id="price" type="text" class="form-control"  formControlName="price" placeholder="Precio" />
      </div>

      <!-- Champ Description -->
      <div class="form-group" style="text-align:left;">
        <label for="description">Descripción*</label>
        <textarea id="description" class="form-control" rows="5" formControlName="description" placeholder="Descripción"></textarea>

      </div>





      <!-- Champ Image -->
      <div class="form-group">
        <label for="image">Imagen</label>
        <input id="image" type="file" class="form-control" formControlName="image" (change)="onFileChange($event)" />

      </div>


      <div formArrayName="allergens">
        <div *ngFor="let allergen of allergenos; let i = index">
          <label class="allergen-label">
            <input type="checkbox" [formControlName]="i">
            <img src="{{ allergen.iconUrl }}" mat-icon-button
                 matTooltip="{{ allergen.name }}" class="round-icon" width="50" />
            {{ allergen.name }}
          </label>
        </div>
      </div>



    </form>
  </div>

  <!-- Footer avec bouton -->
  <div mat-dialog-actions style="justify-content: flex-end; padding-right:20px; margin-bottom:0;">
    <button mat-raised-button color="secondary" type="button" *ngIf="productForm.get('id')?.value" (click)="deleteProduct()">
      Eliminar
    </button>


    <button mat-raised-button color="primary" type="button" [disabled]="productForm.invalid" (click)="onSubmit()">
      Actualizar
    </button>
  </div>
</div>
