import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http';

@Injectable()

export class updateService {
  constructor(private http: HttpClient) { }

  getData() {
    return this.http.get('https://api.barmitierra.app-services.link/api/prestashop/import');
  }
}
