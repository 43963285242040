import {
  AfterViewInit,
  Component,
  ElementRef, HostListener,
  Input,
  NgModule,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductsService} from "../../services/products.service";
import {NgbActiveModal, NgbModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import SwiperCore, {FreeMode, Navigation, Pagination, Thumbs, Zoom} from "swiper";
import {CommonModule, PlatformLocation} from "@angular/common";
import 'hammerjs';
import {CartService} from "../../../core/services/cart.service";
import {ProductModel} from "../../models/product.model";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {map} from "rxjs/operators";
import {LoaderService} from "../../../core/services/loader.service";
import {BeforeSlideDetail} from "lightgallery/lg-events";
import lgZoom from 'lightgallery/plugins/zoom';
import {MatDrawer} from "@angular/material/sidenav";
import {SessionLoginService} from "../../../core/services/session-login.service";
import {ProductDialogComponent} from "../admin/product-dialog/product-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../environments/environment";
import * as Hammer from 'hammerjs';

SwiperCore.use([Zoom, FreeMode, Navigation, Pagination, Thumbs]);


declare const $: any;

/**
 * @title Dialog Animations
 */
@Component({
  selector: 'product-details',
  styleUrls: ['product-details.component.scss'],
  templateUrl: 'product-details.component.html',

  encapsulation: ViewEncapsulation.None,
})


export class ProductDetailsComponent implements AfterViewInit{
  loading$!: Observable<boolean>;
  showLoader: boolean = true;
  product: any;
  isLoading = false;
  flex!: any;
  items$ = this.cartService.itemsInCart$;
  hasItems: Observable<boolean>;
  onInit: any;
  productIds!: any[];
  showFiller = false;


  constructor(
    private translate: TranslateService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService,
    public activeModal: NgbActiveModal,
    private location: PlatformLocation,
    private cartService: CartService,
    private _snackBar: MatSnackBar,
    private loaderService: LoaderService,
    private el: ElementRef,
    private renderer: Renderer2,
    public session: SessionLoginService,
    public dialog: MatDialog
  ) {
    location.onPopState(() => this.modalService.dismissAll());
    this.hasItems = this.cartService.itemsInCartCount$.pipe(map(result => !!result));


    this.errorImage = '/assets/' + environment.client + '/error.png';

    this.route.queryParams.subscribe(params => {
      if(this.productIds) {
          const productIdFromUrl = +params['showProduct'];
          const productIndex = this.productIds.findIndex(product => product.id === productIdFromUrl);
          if (productIndex !== -1) {
            this.currentIndex = productIndex;
          }
      }
    });

  }

  images!: any;
  loaded = 0;
  finish = new Observable;
  fromFavorites!: string;
  errorImage!: string;
  @Input('appLazyLoad') lazyImage!: string;


  isFullscreen = false;
  zoomScale = 1;

  openFullscreen() {
    this.isFullscreen = true;
  }

  // Fermer le modal plein écran
  closeFullscreen() {
    this.isFullscreen = false;
    this.zoomScale = 1; // Réinitialiser le zoom
    this.translateX = 0;
    this.translateY = 0;
  }

  // Gestion du zoom par pincement (pinch)
  onPinchZoom(event: any) {
    const scale = event.scale; // Récupère le facteur de zoom
    this.zoomScale = Math.max(1, Math.min(3, scale)); // Limite le zoom entre 1x et 3x
  }


  @ViewChild('zoomImage') zoomImage!: ElementRef;

  zoomImage2(event: WheelEvent) {
    event.preventDefault();
    if (event.deltaY < 0) {
      // Zoom avant
      this.zoomScale = Math.min(3, this.zoomScale + 0.1);
    } else {
      // Zoom arrière
      this.zoomScale = Math.max(1, this.zoomScale - 0.1);
    }
  }

  endDrag() {
    this.isDragging = false;
    this.zoomImage.nativeElement.style.cursor = 'grab';
  }

  // Démarrer le déplacement de l'image
  startDrag(event: MouseEvent) {
    this.isDragging = true;
    this.initialX = event.clientX - this.translateX;
    this.initialY = event.clientY - this.translateY;
    this.zoomImage.nativeElement.style.cursor = 'grabbing';
  }

  // Déplacer l'image
  drag(event: MouseEvent) {
    if (this.isDragging) {
      event.preventDefault();
      const moveX = event.clientX - this.initialX;
      const moveY = event.clientY - this.initialY;
      this.translateX = moveX;
      this.translateY = moveY;
    }
  }

  ngAfterViewInit(): void {

  }
  currentIndex: number = 0;

  ngOnInit(): void {
    this.handleClick();
    //Set loading to sale
    this.loading$ = this.loaderService.loading$;

    this.loaderService.showLoader();

    this.flex = (window.innerWidth <= 768) ? 'flex-column-reverse flex-lg-row' : '';

    this.cartService.loadCart();
    this.loadImages(this.product.images);

    let id = parseInt(localStorage.getItem('lastId') ?? '15');


    window.addEventListener("orientationchange", () => {
      this.loaderService.showLoader();
      setTimeout(() => {
        this.flex = (window.innerWidth <= 768) ? 'flex-column-reverse flex-lg-row' : '';
        this.loaderService.hideLoader();
      }, 300);
    });


    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 3000);

    this.route.queryParams
      .subscribe(params => {
          console.log(params)
          this.fromFavorites = params['fromFavorites'];

          if(this.fromFavorites) {
            //Set background image
            let background = 'assets/' + environment.client + '/background/home/h.jpg';
            if (window.innerHeight > window.innerWidth) {
              background = 'assets/' + environment.client + '/background/home/v.jpg';
            }

            var myCss = ".modal-content { background:url('" + background + "') no-repeat center center fixed;}";
            var style = document.createElement("STYLE");
            style.innerText = myCss;
            document.body.appendChild(style);
          } else {

            if (id) {
              this.setBackgroundImage(id);
            } else {
              id = parseInt('' + this.route.snapshot.paramMap.get('id'));
              this.setBackgroundImage(id);
            }
          }

        }
      );

  }

  html(text:any) {
    return text.replace(/\n/g, '<br />');
  }

  text(text:any) {
    if(text) {
      return text;
    }
  }

  private loadImages(images: any) {
    for (let image of images) {
      let img = new Image();
      img.onload = () => {
        this.allLoaded(images);
      }
      img.src = image.image1200Url;
    }
  }

  private loadImages800(images: any) {
    for (let image of images) {
      let img = new Image();
      img.onload = () => {
        this.allLoaded(images);
      }
      img.src = image.image1200Url;
    }
  }

  private allLoaded(images: any): void {
    this.loaded++;
    if (images.length == this.loaded) {
      //all images loaded
      this.loaderService.hideLoader();
      return;
    }
  }


  private setBackgroundImage(id: number) {


    let background = 'assets/' + environment.client + '/background/detail/h.jpg';
    if (window.innerHeight > window.innerWidth) {
      background = 'assets/' + environment.client + '/background/detail/v.jpg';
    }
    var myCss = ".modal-content { background: url('" + background + "') no-repeat center center fixed; background-color: rgba(0, 0, 0, 0.7); }";
    var style = document.createElement("STYLE");
    style.innerText = myCss;
    document.body.appendChild(style);

  }

  onResize(event: any) {
    this.flex = (window.innerWidth <= 768) ? 'flex-column-reverse flex-lg-row' : '';
  }

  close() {
    this.modalService.dismissAll()
  }

  onPan(evt: any) {
    const url = this.router.createUrlTree([], {relativeTo: this.route, queryParams: {showProduct: ''}}).toString()
    this.router.navigateByUrl(url, {skipLocationChange: false});
    this.modalService.dismissAll()
  }

  isInMyFavorite(product: ProductModel) {
    if (!this.cartService.itemInCart(product)) {
      return false;
    } else {
      return true;
    }
  }


  addToCart(product: ProductModel) {


    if (!this.cartService.itemInCart(product)) {
      this.cartService.addToCart(product);
      this.modalService.dismissAll()
      this.openSnackBar(product);

    } else {
      this.cartService.deleteItemInCart(product);
      this.modalService.dismissAll()

      let count = this.cartService.getItems();
      //this.cartService.clearCart();

      this.openSnackBarDelete(product);

    }
  }


  translateX = 0;
  translateY = 0;
  initialX = 0;
  initialY = 0;
  isDragging = false;

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  Object = Object;

  settings = {
    counter: false,
    plugins: [lgZoom]
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
    console.log(index, prevIndex);
  };

  openSnackBar(product: any) {
    this.translate.get('deleted_favorite').subscribe((message: string) => {
      this._snackBar.open(product.name + " " + message, '', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: 2000
      });
    });

  }


  openSnackBarDelete(product: any) {
    this.translate.get('deleted_favorite2').subscribe((message: string) => {
      this._snackBar.open(product.name + " " + message, '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000
    });
    });
  }


  @ViewChild('drawer') drawer!: MatDrawer;
  clickCount: number = 0;
  delayBetweenClicks: number = 500; // 500 millisecondes de délai entre les clics
  lastClickTime: number = 0;

  handleClick() {
    const currentTime = Date.now();

    if (currentTime - this.lastClickTime <= this.delayBetweenClicks) {
      this.clickCount++;
    } else {
      this.clickCount = 1;
    }

    if (this.clickCount === 5) {
      this.drawer.toggle();
      this.clickCount = 0;
    }

    this.lastClickTime = currentTime;
  }

  openProductDialog(id:any, product:any): void {
    const dialogRef = this.dialog.open(ProductDialogComponent, {
      width: '800px',
      data: {
        categoryId:id,
        product:product
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result=='deleted') {

        this.activeModal.dismiss('Cross click');
      } else {
        if(result) {
          this.product = result;
        }
      }
    });
  }



  changeProduct(offset: number): void {
    if(!this.isFullscreen) {
      if (this.productIds.length) {
        const newIndex = this.currentIndex + offset;
        if (newIndex >= 0 && newIndex < this.productIds.length) {
          const newProduct = this.productIds[newIndex];
          this.product = newProduct;
          this.currentIndex = newIndex;

          // Mettre à jour l'URL avec le nouvel ID de produit
          this.router.navigate([], {
            queryParams: {showProduct: newProduct.id},
            queryParamsHandling: 'merge',
          });
        }
      }
    }
  }

  // Vérification de la désactivation des flèches
  isFirstProduct(): boolean {
    return this.currentIndex === 0;
  }

  isLastProduct(): boolean {
    if (this.productIds) {
      return this.currentIndex === this.productIds.length - 1;
    }
    return false;
  }

  @HostListener('swipeleft', ['$event'])
  onSwipeLeft(event: any): void {
    this.changeProduct(1); // Si l'utilisateur fait un swipe à gauche, on passe au produit suivant
  }

  @HostListener('swiperight', ['$event'])
  onSwipeRight(event: any): void {
    this.changeProduct(-1); // Si l'utilisateur fait un swipe à droite, on passe au produit précédent
  }
}
