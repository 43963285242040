import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {CategoriesService} from "../../services/categories.service";
import {CategoryModel} from "../../models/category.model";
import {FormBuilder, FormControl} from "@angular/forms";
import {CategorySearchType} from "../../enums/category-search-type.enum";
import {map, startWith} from "rxjs/operators";
import {combineLatest} from "rxjs";
import {NavigationService} from "../../../core/services/navigation.service";
import { OnDestroy} from '@angular/core';
import { Router,NavigationStart, Event as NavigationEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {LanguageChangeService} from "../../services/language-change.service";
import {environment} from "../../../../environments/environment";
import {SessionLoginService} from "../../../core/services/session-login.service";
import {TranslationService} from "../../services/translation.service";
import {CategoryDialogComponent} from "../admin/category-dialog/category-dialog.component";
import {MatDialog} from "@angular/material/dialog";


@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesListComponent implements OnInit {


  loading$!: Observable<boolean>;
  categories$!: Observable<CategoryModel[]>;

  //Search
  searchCtrl!: FormControl;
  searchTypeCtrl!: FormControl;

  searchTypeOptions!: {
    value: CategorySearchType,
    label: string
  }[];

  breakpoint: any;
  gutterSize: any;
  rowHeight: any;
  column: any;
  categoryId: any;
  currentRoute!: string;
  baselang!: string;

  event$

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    public translationService: TranslationService,
    private languageChangeService: LanguageChangeService,
    public session: SessionLoginService,
    private categoriesService: CategoriesService,
    private formBuilder: FormBuilder,
    public navigation: NavigationService,
    private router: Router
  ) {

    this.baselang = environment.lang;



    let lang = localStorage.getItem('selectedLanguage');
    if(lang) {
      this.translate.use(lang);
    }


    this.event$
      =this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if(event instanceof NavigationStart) {
            console.log(event.url);
          }
        });
  }

  ngOnInit(): void {

    this.languageChangeService.currentLanguage$.subscribe((lang) => {
      this.categoriesService.getCategoryFromServer(null);
    });

    this.currentRoute = this.router.url;


    this.categoriesService.cleanCategories();
    this.breakpoint = (window.innerWidth < 1023) ? 2 : 3;
    this.gutterSize = (window.innerWidth < 1023) ? '30px' : '30px';
    this.gutterSize = (window.innerWidth <= 800) ? '15px' : '30px';


    this.column = 'col-lg-4';
    if(window.innerHeight > window.innerWidth){
      this.column = 'col-lg-6';
    }

    this.initForm();

    this.initObservable();

    //Call webservice
    this.categoriesService.getCategoryFromServer();

    this.setBackgroundImage();

  }

  ngOnDestroy(): void {
    //this.categoriesService.cleanCategories();
  }

  private setBackgroundImage() {

    this.column = 'col-lg-4';
    if(window.innerHeight > window.innerWidth){
      this.column = 'col-lg-6';
    }

    this.rowHeight = (window.innerWidth < 1023) ? '220px' : '200px';

    //Set background image
    let background = 'assets/' + environment.client + '/background/home/h.jpg';
    if(window.innerHeight > window.innerWidth){
      background = 'assets/' + environment.client + '/background/home/v.jpg';
    }



    var myCss = ".mat-drawer-container { background:url('" + background + "') no-repeat center center fixed;}";
    var style = document.createElement("STYLE");
    style.innerText = myCss;
    document.body.appendChild(style);
  }


  onResize(event: any) {
    console.log(event.target.innerWidth);
    this.breakpoint = (window.innerWidth < 1023) ? 2 : 3;
    this.gutterSize = (window.innerWidth < 1023) ? '30px' : '30px';
    this.gutterSize = (window.innerWidth <= 800) ? '15px' : '30px';

    this.setBackgroundImage();
  }


  private initForm() {
    this.searchCtrl = this.formBuilder.control('');
    this.searchTypeCtrl = this.formBuilder.control(CategorySearchType.CATEGORY_NAME);
    this.searchTypeOptions = [
      { value: CategorySearchType.CATEGORY_NAME, label: 'Catégorie' },
      { value: CategorySearchType.DESCRIPTION, label: 'Description' },
    ];
  }


  private initObservable() {
    const search$ = this.searchCtrl.valueChanges.pipe(
      startWith(this.searchCtrl.value),
      map(value => value.toLowerCase())
    );

    //Search by type
    const searchType$: Observable<CategorySearchType> = this.searchTypeCtrl.valueChanges.pipe(
      startWith(this.searchTypeCtrl.value)
    );

    this.loading$ = this.categoriesService.loading$;


    this.categories$ = combineLatest([
        search$,
        searchType$,
        this.categoriesService.categories$
      ]
    ).pipe(
      map(([search, searchType, categories]) => categories.filter(category => category[searchType]
        .toLowerCase()
        .includes(search as string))
      )
    );


  }

  openCategoryDialogDialog() {
    const dialogRef = this.dialog.open(CategoryDialogComponent, {
      width: '800px',

    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        location.reload();
      }
    });
  }
}
