import {Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {transferArrayItem, CdkDrag, CdkDragEnd} from '@angular/cdk/drag-drop';
import {Observable} from "rxjs";
import {CategoryModel} from "../../models/category.model";
import {ProductModel} from "../../models/product.model";
import {CategoriesService} from "../../services/categories.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ProductsService} from "../../services/products.service";
import {switchMap} from "rxjs/operators";

import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragMove,
  moveItemInArray,
} from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-products-admin',
  templateUrl: './products-admin.component.html',
  styleUrls: ['./products-admin.component.scss']
})
export class ProductsAdminComponent implements OnInit {
  id!: string;
  loading$!: Observable<boolean>;
  category$!: Observable<CategoryModel>;
  products$!: Observable<ProductModel[]>;

  constructor(
    private categoriesService: CategoriesService,
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService,
    elementRef: ElementRef
  ) {
    this.products$ = this.productsService.products$
  }

  ngOnInit(): void {

    //Set loading to sale
    this.loading$ = this.productsService.loading$;

    //Get id od the category
    let id = parseInt('' + this.route.snapshot.paramMap.get('id'));

    //Get current category
    this.category$ = this.route.params.pipe(
      switchMap(params => this.categoriesService.getCategoryById(+params['id'])) //+ it's for number
    );

    //Get products from the server
    this.productsService.getProductsFromServer(id).subscribe();
  }


  ngOnDestroy(): void {
    this.productsService.cleanProducts()
  }

  movies = [];
  ended!: EventEmitter<CdkDragEnd>


  drop(event: CdkDragDrop<ProductModel[]>) {
    this.loading$ = this.productsService.loading$;


    if (event.previousContainer === event.container) {

      console.log(event.container.data);

      moveItemInArray(event.container.data,
        event.previousIndex,
        event.currentIndex);
    } else {
      //TODO move items between arrays
    }



    setTimeout(()=> {
      let categoryId = ('' + this.route.snapshot.paramMap.get('id'));

      moveItemInArray(this.movies, event.previousIndex, event.currentIndex);
      this.movies = [...this.movies];


      const elArr = document.querySelectorAll('.cdk-drag');
      let params = "?ids=";
      for( var i = 0; i < elArr.length; i++ ) {
        var input       = elArr[i];


        const cId = input.getAttribute('id');

        if(cId) {
          params += cId + ",";
        }
      }

      this.productsService.updatePosition(
        event.item.data.id,
        categoryId,
        event.currentIndex,
        event.previousIndex,
        params
      ).subscribe();


    }, 1500);

    return;



  }





}
