import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageChangeService {
  private currentLanguageSubject = new BehaviorSubject<string>(localStorage.getItem('selectedLanguage') ?? 'es');
  currentLanguage$ = this.currentLanguageSubject.asObservable();

  changeLanguage(lang: string): void {
    localStorage.getItem('selectedLanguage');
    this.currentLanguageSubject.next(lang);
  }


}
