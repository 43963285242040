import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild, ElementRef
} from '@angular/core';


import { CartService} from "../core/services/cart.service";
import {ProductModel} from "../products-categories/models/product.model";
import {ProductDetailsComponent} from "../products-categories/components/product-details/product-details.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "./components/dialog/dialog.component";

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartComponent {

  items$ = this.cartService.itemsInCart$;
  private modalRef!: any
  @ViewChild('closeright') closeright!: ElementRef;

  constructor(
    private cartService: CartService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    public dialogo: MatDialog,
  ) { }

  ngOnInit(): void {

    this.cartService.loadCart();
  }

  /* Open product */
  open(product: ProductModel) {

    this.modalRef = this.modalService.open(ProductDetailsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      fullscreen: true,
      keyboard: true,
      backdrop: 'static'
    });

    const url = this.router.createUrlTree([], {relativeTo: this.route, queryParams: {showProduct: product.id, fromFavorites: true}}).toString();
    this.router.navigateByUrl(url, { skipLocationChange: false});

    return this.modalRef.componentInstance.product = product;
  }

  deleteAll() {
    setTimeout(() => {
      this.bottomSheet.dismiss();
      setTimeout(() => {
        this.cartService.clearCart();
        this.openSnackBarDeleteAll();
      }, 500);
    }, 500);

  }

  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  openSnackBarDeleteAll() {
    this._snackBar.open("¡Sus favoritos han sido vaciados con éxito!", '', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 2000
    });
  }

  confirmDialogDeleteAll(): void {
    this.dialogo
      .open(DialogComponent, {
        data: 'Está a punto de vaciar sus favoritos'
      })
      .afterClosed()
      .subscribe((confirmDelete: Boolean) => {
        if (confirmDelete) {
          this.deleteAll();
        }
      });
  }

}
