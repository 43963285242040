import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CategoryModel } from '../models/category.model';
import { environment } from '../../../environments/environment';
import { delay, tap, map, switchMap, take } from 'rxjs/operators';
import { LanguageChangeService } from './language-change.service';
import {TranslationService} from "./translation.service"; // Import the language service

@Injectable()
export class CategoriesService {
  constructor(
    public http: HttpClient,
    private languageChangeService: LanguageChangeService,
    private TranslationService: TranslationService // Inject the language service
  ) {}

  /* Delais for query */
  private lastTimeCategoriesLoaded = 0;

  /* Loading funcs */
  private _loading$ = new BehaviorSubject<boolean>(false);
  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  /* Observable for categories */
  public _categories$ = new BehaviorSubject<CategoryModel[]>([]);
  get categories$(): Observable<CategoryModel[]> {
    return this._categories$.asObservable();
  }

  public setLoadingStatus(loading: boolean) {
    this._loading$.next(loading);
  }

  cleanCategories() {
    this._categories$.next([]);
  }

  getCategoryFromServer(id?: any) {
    if (!id) {
      id = environment.mainCategory;
    }

    // Show loading
    this.setLoadingStatus(true);

    let loggedIn = localStorage.getItem('loggedIn');
    let url = environment.apiUrl;
    if (loggedIn === 'true') {
      url = environment.apiUrl;
    }

    let lang = this.TranslationService.getCurrentLanguage();

    // Make the API call with the selected language
    this.http
      .get<CategoryModel[]>(`${url}/categories/${id}?lang=${lang}`)
      .pipe(
        delay(1000),
        tap((categories) => {
          this._categories$.next(categories);
          console.log(categories);

          // Hide loading
          this.setLoadingStatus(false);
          this.lastTimeCategoriesLoaded = Date.now();
        })
      )
      .subscribe();
  }

  getCategoryFromServerMariage(id?: any) {
    if (!id) {
      id = 12;
    }

    // Show loading
    this.setLoadingStatus(true);

    let loggedIn = localStorage.getItem('loggedIn');
    let url = environment.baseUrl;
    if (loggedIn === 'true') {
      url = environment.apiUrl;
    }

    this.http
      .get<CategoryModel[]>(`${url}/categories/${id}`)
      .pipe(
        delay(1000),
        tap((categories) => {
          this._categories$.next(categories);
          console.log(categories);

          // Hide loading
          this.setLoadingStatus(false);
          this.lastTimeCategoriesLoaded = Date.now();
        })
      )
      .subscribe();
  }

  getCategoryById(id: number): Observable<CategoryModel> {
    if (!this.lastTimeCategoriesLoaded) {
      this.getCategoryFromServer();
    }

    return this.categories$.pipe(
      map((categories) => categories.filter((category) => category.id === id)[0])
    );
  }

  getCategoryByIdforce(id: number): Observable<CategoryModel> {
    this.getCategoryFromServer();

    return this.categories$.pipe(
      map((categories) => categories.filter((category) => category.id === id)[0])
    );
  }

  deleteCategory(id: number): void {
    this.setLoadingStatus(true);
    this.http
      .get(`${environment.apiUrl}/categories/delete/${id}`)
      .pipe(
        delay(1000),
        switchMap(() => this.categories$),
        take(1),
        map((categories) => categories.filter((category) => category.id !== id)),
        tap((categories) => {
          this._categories$.next(categories);
          this.setLoadingStatus(false);
        })
      )
      .subscribe();
  }

  changeNameToCategory(id: number): void {
    this.categories$
      .pipe(
        take(1),
        map((categories) =>
          categories.map((category) =>
            category.id === id
              ? { ...category, name: 'Test Name' }
              : category
          )
        ),
        tap((updatedcategories) => this._categories$.next(updatedcategories)),
        switchMap((updatedcategories) =>
          this.http.patch(
            `${environment.apiUrl}/categories/updateName/${id}`,
            updatedcategories.find((category) => category.id === id)
          )
        )
      )
      .subscribe();
  }

  reloadCategories(id?: any): void {
    if (!id) {
      id = environment.mainCategory; // Utilisez une catégorie par défaut si aucun ID n'est fourni
    }


    // Afficher l'état de chargement
    this.setLoadingStatus(true);

    let loggedIn = localStorage.getItem('loggedIn');
    let url = environment.apiUrl;
    if (loggedIn === 'true') {
      url = environment.apiUrl;
    }

    let lang = this.TranslationService.getCurrentLanguage();

    this.http
      .get<CategoryModel[]>(`${url}/categories/${id}?lang=${lang}`)
      .pipe(
        delay(1000),
        tap((categories) => {
          this._categories$.next(categories);
          console.log('Categories reloaded:', categories);

          // Cacher l'état de chargement
          this.setLoadingStatus(false);
          this.lastTimeCategoriesLoaded = Date.now();
        })
      )
      .subscribe();
  }

}
