import {Injectable, Input} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {delay, map, switchMap, take, tap} from "rxjs/operators";
import {ProductModel} from "../models/product.model";
import {ProductDetailsComponent} from "../components/product-details/product-details.component";
import {MatDialog} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {TranslationService} from "./translation.service";


@Injectable()
export class ProductsService {

  constructor(
    private TranslationService: TranslationService,
    private http: HttpClient,
    public dialog: MatDialog,
  ) {
  }

  /* Loading funcs */
  private _loading$ = new BehaviorSubject<boolean>(false);
  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  /* Observable for products */
  private _products$ = new BehaviorSubject<ProductModel[]>([])
  get products$(): Observable<ProductModel[]> {
    return this._products$.asObservable();
  }


  public _product$ = new BehaviorSubject<ProductModel[]>([])
  get product(): Observable<ProductModel[]> {
    return this._product$.asObservable();
  }

  private setLoadingStatus(loading: boolean) {
    this._loading$.next(loading);
  }

  cleanProducts() {
    this._products$.next([]);
    this._product$.next([]);
  }



  getProductsFromServer(id:number) {
    this._products$.next([]);

    let lang = this.TranslationService.getCurrentLanguage();

    let loggedIn = localStorage.getItem('loggedIn');
    let url = environment.baseUrl;
    if(loggedIn == 'true') {
      url = environment.apiUrl
    }

    //Show loading
    this.setLoadingStatus(true);
    return this.http.get<ProductModel[]>(`${url}/categories/` + id +`/products?lang=` + lang).pipe(
      map(products => {
        this._products$.next(products);

        //Hide loading
        this.setLoadingStatus(false);


      })
    );
  }


  getProductById(productId: number) {
    let loggedIn = localStorage.getItem('loggedIn');
    let url = environment.baseUrl;
    if(loggedIn == 'true') {
      url = environment.apiUrl
    }


    //Show loading
    this.setLoadingStatus(true);
    this.http.get<ProductModel[]>(`${url}/categories/${environment.mainCategory}`).pipe(
      delay(1000),
      tap(product => {

        //In in categories
        this._product$.next(product);
        console.log(product);

        //Hide loading
        this.setLoadingStatus(false);


      })
    ).subscribe();
  }

  deleteProduct(id: number): void {

    this.setLoadingStatus(true);
    this.http.get(`${environment.apiUrl}/products/delete/${id}`).pipe(
      delay(1000),
      switchMap(() => this.products$),
      take(1),
      map(products => products.filter(product => product.id !== id)),
      tap(products => {
        this._products$.next(products);
        this.setLoadingStatus(false);
      })
    ).subscribe();
  }



  updatePosition(id: number, categoryId:string, position: number, previousIndex:number, params:any) {

    //Show loading
    this.setLoadingStatus(true);


    return this.http.get<ProductModel[]>(`${environment.baseLoginUrl}/products/updatePosition/${id}/${categoryId}/${position}/${previousIndex}${params}`).pipe(
      //delay(1000000),
      map(products => {

        //In in products
        this._products$.next(products);

        //Hide loading
        this.setLoadingStatus(false);

      })
    );

  }
  filterProductsByReference(reference: string): Observable<ProductModel[]> {
    return this.products$.pipe(
      map((products: ProductModel[]) => {
        return products.filter(product => {
          for (const key in product) {
            if (Object.prototype.hasOwnProperty.call(product, key)) {
              const value = product[key as keyof ProductModel];
              if (typeof value === 'string' && value.includes(reference)) {
                return true;
              }
              if (typeof value === 'number' && value.toString().includes(reference)) {
                return true;
              }
            }
          }
          return false;
        });
      })
    );
  }


}
